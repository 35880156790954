<template>
    <div>
    </div>
</template>
<script>
    export default {
        name:'index',
        data() {
            return {
                
            }
        },
        created() {
            // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa44445e62d5493b8&redirect_uri='+(encodeURIComponent('http://thlive.thape.com.cn:443/login/code?platform=mobile&activityid='+this.$route.query.activityid))+'&response_type=code&scope=snsapi_userinfo&agentid='+(this.$route.query.activityid == 'undefined' || this.$route.query.activityid == '10253584' ? '1000118' : '1000126')+'&state=STATE#wechat_redirect';
            window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa44445e62d5493b8&redirect_uri='+(encodeURIComponent('https://thlive.thape.com.cn/login/code?platform=mobile'))+'&response_type=code&scope=snsapi_userinfo&agentid=1000118&state=STATE#wechat_redirect';
        },
        methods: {
            goWx() {
                // window.location.href = 'https://open.weixin.qq.com/connect/oauth2/authorize?appid=wxa44445e62d5493b8&redirect_uri='+(encodeURIComponent('http://thlive.thape.com.cn:443/login/code'))+'&response_type=code&scope=snsapi_userinfo&agentid=1000118&state=STATE#wechat_redirect';
            }
        }
    }
</script>
